import { WALLET_ADAPTERS, CHAIN_NAMESPACES } from '@web3auth/base'

import { SERVICE_NAME } from './constants'

const web3AuthNetwork = 'cyan'

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: process.env.NEXT_PUBLIC_CHAIN_ID,
  rpcTarget: process.env.NEXT_PUBLIC_RPC_TARGET,
}

export const web3AuthOptions = {
  clientId: process.env.NEXT_PUBLIC_WEB3AUTH_CLIENT_ID,
  web3AuthNetwork,
  authMode: 'DAPP',
  chainConfig,
  uiConfig: {
    // TODO: RuckPlusのロゴを設定する
    // appLogo:
    //   'https://www.ruckplus.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo_white.f52f0a5b.png&w=256&q=75',
    // TODO: ダークモード/ライトモードを切り替える
    theme: 'dark',
    defaultLanguage: 'ja',
  },
}

export const openloginAdapterOptions = {
  adapterSettings: {
    uxMode: 'popup',
    network: web3AuthNetwork,
    /*
    whiteLabel: {
      name: SERVICE_NAME,
      logoLight: 'https://web3auth.io/images/w3a-L-Favicon-1.svg',
      logoDark: 'https://web3auth.io/images/w3a-D-Favicon-1.svg',
      defaultLanguage: 'ja',
      dark: true, // whether to enable dark mode. defaultValue: false
    },
    */
  },
}

export const metamaskAdapterOptions = {
  clientId: process.env.NEXT_PUBLIC_WEB3AUTH_CLIENT_ID,
  sessionTime: 3600,
  web3AuthNetwork,
  chainConfig,
}

// DOCS: https://web3auth.io/docs/sdk/web/modal/whitelabel
// "google", "facebook", "twitter", "reddit", "discord", "twitch", "apple", "line", "github", "kakao", "linkedin", "weibo", "wechat", "email_passwordless
export const modalConfig = {
  [WALLET_ADAPTERS.OPENLOGIN]: {
    label: 'openlogin',
    loginMethods: {
      twitter: {
        name: 'twitter',
        showOnModal: true,
      },
      facebook: {
        name: 'facebook',
        showOnModal: false,
      },
      google: {
        name: 'google',
        showOnModal: true,
      },
      reddit: {
        name: 'reddit',
        showOnModal: false,
      },
      discord: {
        name: 'discord',
        showOnModal: true,
      },
      twitch: {
        name: 'twitch',
        showOnModal: false,
      },
      apple: {
        name: 'apple',
        showOnModal: false,
      },
      line: {
        name: 'line',
        showOnModal: true,
      },
      github: {
        name: 'github',
        showOnModal: true,
      },
      kakao: {
        name: 'kakao',
        showOnModal: false,
      },
      linkedin: {
        name: 'linkedin',
        showOnModal: false,
      },
      weibo: {
        name: 'weibo',
        showOnModal: false,
      },
      wechat: {
        name: 'wechat',
        showOnModal: true,
      },
      email_passwordless: {
        name: 'email',
        showOnModal: false,
      },
      sms_passwordless: {
        name: 'sms',
        showOnModal: false,
      },
    },
    showOnModal: true,
  },
}
