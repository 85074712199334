import Head from 'next/head'

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  )
}

Meta.defaultProps = {
  title: 'Ruck+ | NFT Marketplace',
  keyword:
    'bitcoin, blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet',
  desc: 'クリプトコレクティブルと非代替トークン (NFT) の世界初かつ最大のデジタルマーケットプレイス。限定デジタル アイテムを購入、販売、発見できます。',
}

export default Meta
