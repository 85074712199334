import { ethers } from 'ethers'

export default class EthereumRpc {
  provider

  constructor(provider) {
    this.provider = provider
  }

  async getAccounts() {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(this.provider)
      const signer = ethersProvider.getSigner()

      // Get user's Ethereum public address
      const address = await signer.getAddress()

      return address
    } catch (error) {
      return error
    }
  }

  async getBalance() {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(this.provider)
      const signer = ethersProvider.getSigner()

      const address = await signer.getAddress()

      const balance = ethers.utils.formatEther(
        await ethersProvider.getBalance(address), // Balance is in wei
      )

      // 小数点第三位まで表示
      return balance.slice(0, balance.indexOf('.') + 4)
    } catch (error) {
      return error
    }
  }
}
