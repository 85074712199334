import { useSelector, useDispatch } from 'react-redux'
import { useSetRecoilState } from 'recoil'

import { useWeb3auth, useWeb3authProvider, useWeb3authStatus } from '../../contexts/Web3authContext'
import { walletModalShow } from '../../redux/counterSlice'
import { accountState } from '../../states/accountState'
import RPC from '../../utils/ethersRPC'

export default function WalletButton() {
  const dispath = useDispatch()
  const { status } = useSelector((state) => state.user)
  const setAccount = useSetRecoilState(accountState)

  const { web3auth } = useWeb3auth()
  const { setWeb3authProvider } = useWeb3authProvider()
  const { web3authStatus, setWeb3authStatus } = useWeb3authStatus()

  const walletHandler = () => {
    if (status === 'unavailable') {
      dispath(walletModalShow())
    }
  }

  const handleConnectButtonClick = async () => {
    if (!web3auth) {
      console.log('web3auth not initialized yet')
      return
    }

    const web3authProvider = await web3auth.connect()
    setWeb3authStatus(web3auth.status)
    setWeb3authProvider(web3authProvider)

    const rpc = new RPC(web3authProvider)
    const address = await rpc.getAccounts()

    console.log(address)

    setAccount(web3auth.provider.selectedAddress)
  }

  const authenticateUser = async () => {
    const user = await web3auth.authenticateUser()
    console.log(user)
  }

  switch (web3authStatus) {
    case 'initializing':
      return <div>Loading...</div>
    case 'unavailable':
      return (
        <button
          onClick={walletHandler}
          className="js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
          </svg>
        </button>
      )
    case 'connected':
      return null
    case 'ready':
      return (
        <button
          onClick={handleConnectButtonClick}
          className="js-wallet border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={24}
            height={24}
            className="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M22 6h-7a6 6 0 1 0 0 12h7v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2zm-7 2h8v8h-8a4 4 0 1 1 0-8zm0 3v2h3v-2h-3z" />
          </svg>
        </button>
      )
    default:
      return <div>Loading...</div>
  }
}
