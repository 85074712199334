import { createContext, useContext, useEffect, useState } from 'react'

import { Web3Auth } from '@web3auth/modal'
import { OpenloginAdapter } from '@web3auth/openlogin-adapter'

import { web3AuthOptions, modalConfig, openloginAdapterOptions } from '../config/web3Auth'

export const Web3authContext = createContext()
export const Web3authProviderContext = createContext()
export const Web3authStatusContext = createContext()

export const Web3authProvider = ({ children }) => {
  const [web3auth, setWeb3auth] = useState(null)
  const [web3authProvider, setWeb3authProvider] = useState(null)
  const [web3authStatus, setWeb3authStatus] = useState('not_ready')

  useEffect(() => {
    try {
      ;(async () => {
        const web3auth = new Web3Auth(web3AuthOptions)
        const openloginAdapter = new OpenloginAdapter(openloginAdapterOptions)
        web3auth.configureAdapter(openloginAdapter)
        setWeb3auth(web3auth)
        await web3auth.initModal({ modalConfig })
        if (web3auth.provider) {
          setWeb3authProvider(web3auth.provider)
        }
        setWeb3authStatus(web3auth.status)
      })()
    } catch (error) {
      console.log('Web3authProvider error', error)
      setWeb3authStatus(web3auth.status)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Web3authContext.Provider value={{ web3auth, setWeb3auth }}>
      <Web3authProviderContext.Provider value={{ web3authProvider, setWeb3authProvider }}>
        <Web3authStatusContext.Provider value={{ web3authStatus, setWeb3authStatus }}>
          {children}
        </Web3authStatusContext.Provider>
      </Web3authProviderContext.Provider>
    </Web3authContext.Provider>
  )
}

export const useWeb3auth = () => useContext(Web3authContext)
export const useWeb3authProvider = () => useContext(Web3authProviderContext)
export const useWeb3authStatus = () => useContext(Web3authStatusContext)
