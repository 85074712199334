import '../styles/globals.css'
import { MetaMaskProvider } from 'metamask-react'
import { ThemeProvider } from 'next-themes'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { Provider } from 'react-redux'
import { RecoilRoot } from 'recoil'

import Layout from '../components/layout'
import Meta from '../components/Meta'
import UserContext from '../components/UserContext'
import { Web3authProvider } from '../contexts/Web3authContext'
import { store } from '../redux/store'

function MyApp({ Component, pageProps }) {
  const router = useRouter()
  const pid = router.asPath
  const scrollRef = useRef({
    scrollPos: 0,
  })

  useEffect(() => {
    // if (pid === '/home/home_8') {
    // 	const html = document.querySelector('html');
    // 	html.classList.remove('light');
    // 	html.classList.add('dark');
    // }
  }, [])

  return (
    <>
      <Meta title="Home || Ruck+ | NFT Marketplace" />

      <RecoilRoot>
        <Provider store={store}>
          <ThemeProvider enableSystem={true} attribute="class">
            <MetaMaskProvider>
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
                <Web3authProvider>
                  {pid === '/login' ? (
                    <Component {...pageProps} />
                  ) : (
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                  )}
                </Web3authProvider>
              </UserContext.Provider>
            </MetaMaskProvider>
          </ThemeProvider>
        </Provider>
      </RecoilRoot>
    </>
  )
}

export default MyApp
