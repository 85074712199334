import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: 'initializing',
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      const status = action.payload
      state.status = status ?? 'ready'
    },
  },
})

export const { updateStatus } = userSlice.actions

export default userSlice.reducer
